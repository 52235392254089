import { React, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenModal,
  setOpenCaseCloseModal,
} from 'pages/report-summary/redux/report-summary.slice';
import {
  getReportByCaseIdHr,
  generateReportPdf,
  getChecklist,
} from 'pages/report-summary/redux/report-summary.thunk';
import { capitalizeFirstLetter } from 'common/utils/string.utils';
import {
  joinWitnesses,
  joinPerpetrators,
} from 'pages/report-summary/utils/report-summary.utils';
import { formatDate } from 'common/utils/date-time.utils';
import FramelessLayout from 'common/layouts/frameless/frameless.layouts';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  ImageList,
  ImageListItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CaseAssignmentModal from 'pages/report-summary/modals/case-assignment.modals';
import ConfirmDeleteChecklistSubItem from 'pages/report-summary/modals/confirm-delete.modals';
import AddItemModal from 'pages/report-summary/modals/add-item.modals';
import LabelValuePair from 'pages/report-summary/components/label-value-pair.components';
import CloseCaseModal from 'pages/report-summary/modals/close-case.modals';
import ChatBox from 'pages/report-summary/components/chat-box.components';
import Loading from 'common/components/loading/Loading.components';
import { fetchUnreadMessages } from 'pages/notifications/redux/notifications.thunk';
import Checklist from 'pages/report-summary/components/checklist.components';
import { getChecklistAPI } from 'pages/report-summary/services/report-summary.services';
import { getStage } from 'pages/reports/utils/reports.utils';

//  eslint-disable-next-line
// import { getDatabase, ref, set, update, onValue } from 'firebase/database';

function ReportSummary() {
  // const db = getDatabase();
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const report = useSelector((state) => state.reportSummary.report);
  const user = useSelector((state) => state.auth.user);
  const isDownloading = useSelector(
    (state) => state.reportSummary.isDownloading
  );
  const claims = useSelector((state) => state.auth.claims);

  useEffect(() => {
    // const userId = 'dwH1jp3xBUVs3EDT9XwachaL9Ou2';
    // const dbRef = ref(db, `users/${userId}`);
    // // WRITE
    // set(dbRef, {
    //   emailChat: true,
    //   caseSubmitted: true,
    //   caseAssigned: true,
    //   caseCloseRequested: true,
    //   caseClosed: true,
    //   attAdded: true,
    // }).then((res) => console.log(res));

    // READ
    // onValue(dbRef, (snapshot) => {
    //   const data = snapshot.val();
    //   console.log(data);
    // });

    // UPDATE
    // const keyToUpdate = 'caseClosed';
    // const updateTo = false;

    // update(dbRef, { [keyToUpdate]: updateTo })
    //   .then(() => {
    //     console.log('Data updated');
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });

    // pubnub.deleteMessages(
    //   {
    //     channel: `notifications${user.uid}`,
    //     end: '15088506076921021',
    //   },
    //   (result) => {
    //     console.log(result);
    //   }
    // );

    dispatch(fetchUnreadMessages());
    dispatch(getReportByCaseIdHr(caseId));
    dispatch(getChecklist(caseId));
  }, []);

  const handleDownload = async () => {
    try {
      const checklist = await getChecklistAPI(caseId);
      const status = getStage(checklist, report.caseStatus);
      const newReport = { ...report, status };
      dispatch(generateReportPdf(newReport));
    } catch (error) {
      console.error(error);
    }
  };

  const detailsContainerSections = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 4,
  };

  const detailsContainerSpacing = {
    paddingTop: 3,
    paddingRight: 4,
    paddingBottom: 3,
    paddingLeft: 4,
  };

  const detailsGroup = { flexGrow: 1 };

  const detailsLabel = {
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 3,
    paddingBottom: 2,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 75,
      height: 1,
      borderBottom: '3px solid',
      borderColor: 'accent.main',
    },
  };

  const detailsValue = { fontWeight: 500 };

  return !formatDate(report.dateModified).includes('NaN') ? (
    <>
      <FramelessLayout>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 3,
          }}
        >
          <Button
            href="/reports"
            sx={{
              textTransform: 'none',
              color: 'text.allowed',
              fontWeight: 600,
              textDecoration: 'underline',
              '& .MuiButton-startIcon': {
                '& >*:nth-of-type(1)': {
                  fontSize: 20,
                },
              },
            }}
            startIcon={<ArrowBackIosNewIcon sx={{ color: 'icons.primary' }} />}
          >
            Return to list
          </Button>
          {claims.admin && report.caseStatus !== 'CLOSED' && (
            <Box>
              {report.repName ? (
                <Button
                  onClick={() => dispatch(setOpenModal())}
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'underline',
                    color: 'text.allowed',
                    fontWeight: 600,
                    '& .MuiButton-startIcon': {
                      '& >*:nth-of-type(1)': {
                        fontSize: 20,
                      },
                    },
                  }}
                  endIcon={<EditIcon sx={{ color: 'icons.primary' }} />}
                >
                  {`Assigned to ${report.repName}`}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{}}
                  onClick={() => dispatch(setOpenModal())}
                >
                  Assign
                </Button>
              )}
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="page_title"
            sx={{ marginLeft: '20px', marginBottom: 2 }}
          >
            Report summary
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1.2fr 1fr',
            gap: 2,
            marginLeft: '20px',
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.background4',
              borderRadius: theme.spacing(2),
              paddingTop: 3,
            }}
          >
            <Box
              sx={{
                ...detailsContainerSections,
                ...detailsContainerSpacing,
                // ...detailsContainerAccent,f
              }}
            >
              <LabelValuePair label="Reporter" value={report.employee} />
              <LabelValuePair
                label="Received"
                value={formatDate(report.dateModified)}
              />
              <LabelValuePair
                label="Type of incident"
                value={report.typeName}
              />
              <LabelValuePair
                label="Role"
                value={report.role && capitalizeFirstLetter(report.role)}
              />
              <LabelValuePair
                label="Time period"
                value={report.reocurring ? 'Reoccuring' : 'Once'}
              />
              <LabelValuePair
                label="Witness"
                value={
                  report.witnesses?.length
                    ? joinWitnesses(report.witnesses)
                    : 'No'
                }
              />
              <LabelValuePair
                label="Perpetrator"
                value={
                  report.perpetrators?.length
                    ? joinPerpetrators(report.perpetrators)
                    : 'No'
                }
              />
              <Box
                sx={{
                  ...detailsGroup,
                }}
              >
                <Typography
                  sx={{
                    ...detailsLabel,
                    color: report.outcome ? '' : 'text.disabled',
                  }}
                >
                  Desired Outcome
                </Typography>
                <Typography sx={{ ...detailsValue }}>
                  {report.outcome}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                ...detailsContainerSpacing,
              }}
            >
              <Box
                sx={{
                  ...detailsGroup,
                }}
              >
                <Typography
                  sx={{
                    ...detailsLabel,
                    color: report.text ? '' : 'text.disabled',
                  }}
                >
                  Further details
                </Typography>
                <Typography sx={{ ...detailsValue }}>{report.text}</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                ...detailsContainerSpacing,
              }}
            >
              {report.attachments.length > 0 && (
                <LabelValuePair label="Attachments" />
              )}
              <ImageList
                // sx={{ width: 500, height: 450 }}
                variant="quilted"
                cols={6}
                rowHeight={164}
              >
                {report.attachments.map((item) => (
                  <ImageListItem
                    key={item.link}
                    onClick={() => window.open(item.link, '_blank')}
                    sx={{
                      '&:hover, :focus': {
                        opacity: 0.3,
                      },
                    }}
                  >
                    <img
                      src={item.link}
                      alt="Report documentation"
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </Box>
          <Checklist />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 3,
          }}
        >
          {isDownloading ? (
            <CircularProgress />
          ) : (
            <Button
              sx={{
                textTransform: 'none',
                fontWeight: 600,
              }}
              startIcon={<FileDownloadIcon />}
              onClick={handleDownload}
            >
              Download the report
            </Button>
          )}
          {/* show if case is assigned, and the logged in user is (1)admin or (2)the assigned hr */}
          {/* {report.hrRepFk && (user.uid === report.hrRepFk || claims.admin) && ( */}
          {/* ^ABOVE: ADMIN CAN CLOSE CASES */}
          {/* ^CURRENT (BELOW): ADMIN CANNNOT REQUEST TO CLOSE CASES */}
          {report.hrRepFk && user.uid === report.hrRepFk && (
            <Button
              variant="contained"
              sx={{}}
              // onClick={() => requestCloseCase()}
              onClick={() => {
                dispatch(setOpenCaseCloseModal());
              }}
              disabled={report.caseStatus === 'CLOSED'}
            >
              {report.caseStatus === 'CLOSED'
                ? 'Case closed'
                : 'Mark as closed'}
            </Button>
          )}
        </Box>
        <ChatBox />
      </FramelessLayout>
      <CaseAssignmentModal />
      <CloseCaseModal />
      <AddItemModal />
      <ConfirmDeleteChecklistSubItem />
    </>
  ) : (
    <Loading />
  );
}

export default ReportSummary;
