import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setCloseCaseCloseModal } from 'pages/report-summary/redux/report-summary.slice';
import { Modal, Box, Typography, Button } from '@mui/material';
import { sendNotification } from 'pages/notifications/redux/notifications.thunk';
import { usePubNub } from 'pubnub-react';

function AddItemModal() {
  const dispatch = useDispatch();
  const { caseId } = useParams();
  const channelName = `channel${caseId}`;
  const pubnub = usePubNub();
  const report = useSelector((state) => state.reportSummary.report);
  const openModal = useSelector(
    (state) => state.reportSummary.openCaseCloseModal
  );

  const requestCloseCase = () => {
    pubnub.publish({
      channel: channelName,
      message: {
        type: 'closeRequest',
        text: 'Do you want to close your case?',
      },
    });
    // .then(() => {
    // dispatch(setHRLastRead({ channelName, timetoken: res.timetoken }))
    // ^ in case we decide that they don't need to see indicator
    // });

    dispatch(
      sendNotification({
        notification: {
          text: `You have requested to close ${report.typeName} case ${caseId}.`, // by repName?
          type: 'caseCloseRequested',
          caseId,
        },
        recipient: report.hrRepFk,
        officeId: report.officeFk,
      })
    );
  };

  return (
    <Modal open={openModal} onClose={() => dispatch(setCloseCaseCloseModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 440,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 3,
          //   pt: 3,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="page_title_subtext"
          component="h3"
          sx={{
            textAlign: 'center',
            marginBottom: 3,
          }}
        >
          In order to mark this case as closed, a notification will be sent to
          the reporter confirming the closure of the case.
          <br />
          Do you want to proceed?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => dispatch(setCloseCaseCloseModal())}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              requestCloseCase();
              dispatch(setCloseCaseCloseModal());
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddItemModal;
