import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Paper, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { formatDate } from 'common/utils/date-time.utils';
import { getChecklistAPI } from 'pages/report-summary/services/report-summary.services';
import { getStage, assignChipColor } from 'pages/reports/utils/reports.utils';

function Reports() {
  const theme = useTheme();
  const reports = useSelector((state) => state.reports.rows);
  const fiveMostRecent = reports.slice(0, 5);
  const [mapReportToStatus, setMapReportToStatus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      fiveMostRecent.forEach(async (report) => {
        const checklist = await getChecklistAPI(report.caseId);
        setMapReportToStatus((prevMap) => ({
          ...prevMap,
          [report.caseId]: getStage(checklist, report.caseStatus),
        }));
      });
    };
    fetchData();
  }, [reports]);

  return (
    <Paper sx={{ padding: 3, minHeight: 390 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
          marginTop: 1,
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 800,
            marginRight: 'auto',
            // marginBottom: 1,
            textTransform: 'uppercase',
          }}
        >
          {/* eslint-disable-next-line */}
          {'Reports ('}
          {reports.length}
          {/* eslint-disable-next-line */}
          {')'}
        </Typography>
        <Link
          to="/reports"
          style={{
            color: theme.palette.text.allowed,
            fontWeight: 600,
          }}
        >
          View all
        </Link>
      </Box>
      {fiveMostRecent.map((item) => (
        <Box
          key={item.dateModified}
          sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
        >
          <Box sx={{ marginRight: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Link
                to={`/report-summary/${item.caseId}`}
                style={{
                  fontWeight: 600,
                  textDecoration: 'underline',
                  color: theme.palette.text.allowed,
                }}
              >
                {/* eslint-disable-next-line */}
                {item.caseId} {item.typeName}
              </Link>
            </Box>
            <Typography variant="subtitle2">
              {formatDate(item.dateModified)}
            </Typography>
          </Box>
          <Chip
            size="small"
            label={mapReportToStatus[item.caseId]}
            sx={{
              color: 'text.allowed',
              backgroundColor: assignChipColor(mapReportToStatus[item.caseId]),
              fontWeight: '600',
            }}
          />
        </Box>
      ))}
    </Paper>
  );
}

export default Reports;
