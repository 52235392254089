import { createSlice } from '@reduxjs/toolkit';
import {
  getReportByCaseIdHr,
  generateReportPdf,
  setHRLastRead,
  updateChecklistItem,
  updateChecklistSubItem,
  getChecklist,
  addChecklistSubItem,
  deleteChecklistSubItem,
} from 'pages/report-summary/redux/report-summary.thunk';

const reportSummarySlice = createSlice({
  name: 'reportSummary',

  initialState: {
    report: {},
    openModal: false,
    openAddModal: false,
    openDeleteModal: false,
    openCaseCloseModal: false,
    assignedTo: '',
    confirmSelection: false,
    isDownloading: false,
    chatOpen: false,
    checklistData: null,
    loading: false,
    currentItem: '',
    currentId: '',
    currentLabel: '',
  },

  reducers: {
    setReportCaseStatus: (state, action) => {
      state.report.caseStatus = action.payload;
    },
    setChatOpen: (state, action) => {
      state.chatOpen = action.payload;
    },
    setOpenModal: (state) => {
      state.openModal = true;
    },

    setCloseModal: (state) => {
      state.assignedTo = '';
      state.confirmSelection = false;
      state.openModal = false;
    },

    setOpenCaseCloseModal: (state) => {
      state.openCaseCloseModal = true;
    },
    setCloseCaseCloseModal: (state) => {
      state.openCaseCloseModal = false;
    },

    setOpenAddModal: (state, action) => {
      state.openAddModal = true;
      state.currentItem = action.payload;
    },

    setCloseAddModal: (state) => {
      state.openAddModal = false;
    },

    setOpenDeleteModal: (state, action) => {
      state.openDeleteModal = true;
      console.log(action.payload);
      state.currentId = action.payload.subItemId;
      state.currentLabel = action.payload.label;
    },

    setCloseDeleteModal: (state) => {
      state.openDeleteModal = false;
    },

    setAssignedTo: (state, action) => {
      state.assignedTo = action.payload;
    },

    setConfirmSelection: (state) => {
      state.confirmSelection = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setHRLastRead.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(setHRLastRead.fulfilled, (state) => {
      state.loading = false;
      state.unreadMessages = [];
    });

    builder.addCase(setHRLastRead.rejected, (_, action) => {
      console.error('setHRLastRead.rejected: ', action);
    });

    builder.addCase(generateReportPdf.pending, (state) => {
      state.isDownloading = true;
    });

    builder.addCase(generateReportPdf.fulfilled, (state) => {
      state.isDownloading = false;
    });

    builder.addCase(getReportByCaseIdHr.rejected, (_, action) => {
      console.error('getReportByCaseIdHr.rejected: ', action);
    });

    // builder.addCase(updateChecklistItem.pending, (state) => {
    //   state.isDownloading = true;
    // });
    // TO -DO - cases for updateChecklistSubItem
    // UPDATE CHECKLIST

    builder.addCase(updateChecklistSubItem.fulfilled, (state, action) => {
      state.checklistData = action.payload;
    });

    builder.addCase(updateChecklistSubItem.rejected, (_, action) => {
      console.error('updateChecklistSubItem.rejected: ', action);
    });

    builder.addCase(updateChecklistItem.fulfilled, (state, action) => {
      state.checklistData = action.payload;
    });

    builder.addCase(updateChecklistItem.rejected, (_, action) => {
      console.error('updateChecklistItem.rejected: ', action);
    });

    // add checklist sub item
    builder.addCase(addChecklistSubItem.fulfilled, (state, action) => {
      state.checklistData = action.payload;
    });

    builder.addCase(addChecklistSubItem.rejected, (_, action) => {
      console.error('addChecklistSubItem.rejected: ', action);
    });
    // delete checklist sub item
    builder.addCase(deleteChecklistSubItem.fulfilled, (state, action) => {
      state.checklistData = action.payload;
    });

    builder.addCase(deleteChecklistSubItem.rejected, (_, action) => {
      console.error('deleteChecklistSubItem.rejected: ', action);
    });

    // GET CHECKLIST
    builder.addCase(getChecklist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChecklist.fulfilled, (state, action) => {
      state.checklistData = action.payload;
      state.loading = false;
    });

    builder.addCase(getChecklist.rejected, (_, action) => {
      console.error('getChecklist.rejected: ', action);
    });

    builder.addCase(getReportByCaseIdHr.fulfilled, (state, action) => {
      const {
        attachmentRows: attachments,
        perpsRows: perpetrators,
        reportRow: [reportRow],
        witnessRows: witnesses,
      } = action.payload.data;

      const {
        dateModified,
        employee,
        hrRepFk,
        officeFk,
        repName,
        userId,
        reportId: id,
        reportOutcome: outcome,
        reportReoccuring: reoccuring,
        reportRole: role,
        reportText: text,
        typeName,
        caseStatus,
      } = reportRow;

      state.report = {
        userId,
        attachments,
        perpetrators,
        witnesses,
        dateModified,
        employee,
        hrRepFk,
        officeFk,
        repName,
        id,
        outcome,
        reoccuring,
        role,
        text,
        typeName,
        caseStatus,
      };
    });
  },
});

export const {
  setOpenCaseCloseModal,
  setCloseCaseCloseModal,
  setOpenModal,
  setCloseModal,
  setAssignedTo,
  setConfirmSelection,
  setReportCaseStatus,
  setChatOpen,
  setOpenAddModal,
  setCloseAddModal,
  setOpenDeleteModal,
  setCloseDeleteModal,
} = reportSummarySlice.actions;
export default reportSummarySlice.reducer;
