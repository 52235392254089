import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';
import { usePubNub } from 'pubnub-react';
import { setHRLastRead } from 'pages/report-summary/redux/report-summary.thunk';
// eslint-disable-next-line
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import {
  setReportCaseStatus,
  setChatOpen,
} from 'pages/report-summary/redux/report-summary.slice';
import { formatDate } from 'common/utils/date-time.utils';
import { fetchUnreadMessages } from 'pages/notifications/redux/notifications.thunk';
import {
  sendNotificationEmailAPI,
  fetchNotificationsPreferencesAPI,
} from 'pages/notifications/services/notifications.services';

function ChatBox() {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const { caseId } = useParams();
  const theme = useTheme();
  // const [openChat, setOpenChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const channelName = `channel${caseId}`;
  const [channels] = useState([channelName]);
  const user = useSelector((state) => state.auth.user);
  const report = useSelector((state) => state.reportSummary.report);
  const chatOpen = useSelector((state) => state.reportSummary.chatOpen);
  const [newChatsIndicator, setNewChatsIndicator] = useState(false);
  const unreadMessages = useSelector(
    (state) => state.notifications.unreadMessages
  );

  const handleMessage = (event) => {
    if (chatOpen && user.uid === report.hrRepFk) {
      // console.log('setting HR last read ', event.timetoken);
      dispatch(setHRLastRead({ channelName, timetoken: event.timetoken }));
    } else {
      // console.log('setting new chats indicator to true');
      setNewChatsIndicator(true);
    }

    const newMessage = {
      sender: event.publisher,
      message: event.message,
      sentTime: event.timetoken / 10000,
    };
    setMessages((values) => [...values, newMessage]);
    if (event.message.type === 'closeConfirmation') {
      dispatch(setReportCaseStatus('CLOSED'));
    }
  };

  const onSend = (message) => {
    pubnub
      .publish({
        channel: channels[0],
        message: {
          text: message,
          type: 'normal',
        },
      })
      .then(async (res) => {
        // send notification email to user
        const prefs = await fetchNotificationsPreferencesAPI(report.userId);
        if (prefs.mobileNotifications) {
          sendNotificationEmailAPI({
            uid: report.userId,
            subject: 'New message available on your Metta Space app.',
            notification_text: `You've received a new message for report ${caseId}.`,
          });
        }
        dispatch(setHRLastRead({ channelName, timetoken: res.timetoken }));
      });
  };

  useEffect(() => {
    setNewChatsIndicator(
      unreadMessages.some((element) => element.caseId.toString() === caseId)
    );

    const listener = { message: handleMessage };
    pubnub.addListener(listener);
    pubnub.subscribe({ channels });
    pubnub.setUUID(`office${report.officeFk}`);

    const newMessages = [];
    pubnub.fetchMessages(
      {
        channels: [channelName],
        end: '15343325004275466',
        count: 100,
      },
      (_, response) => {
        if (Object.keys(response.channels).length > 0) {
          response.channels[channelName].forEach((element) =>
            newMessages.push({
              sender: element.uuid,
              message: element.message,
              sentTime: element.timetoken / 10000,
            })
          );
          setMessages(newMessages);
        }
      }
    );

    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribeAll();
    };
  }, [
    channelName,
    channels,
    report.officeFk,
    pubnub,
    chatOpen,
    unreadMessages,
  ]);

  return (
    <Box
      sx={{
        position: 'fixed',
        width: 350,
        height: 350,
        bottom: chatOpen ? 50 : -300,
        right: theme.spacing(5),
      }}
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          color: 'background.paper',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 50,
          borderTopLeftRadius: theme.spacing(2),
          borderTopRightRadius: theme.spacing(2),
          cursor: 'pointer',
        }}
        tabIndex={0}
        onClick={() => {
          if (!chatOpen && user.uid === report.hrRepFk) {
            const d = new Date();
            const time = d.getTime();
            dispatch(setHRLastRead({ channelName, timetoken: time * 10000 }));
          }
          dispatch(fetchUnreadMessages());
          setNewChatsIndicator(false);
          dispatch(setChatOpen(!chatOpen));
        }}
      >
        {newChatsIndicator && !chatOpen && (
          <CircleIcon
            sx={{
              fontSize: theme.spacing(3),
              right: theme.spacing(3),
              marginRight: 1,
            }}
            color="warning"
          />
        )}
        <Typography sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
          Messages
        </Typography>

        {chatOpen ? (
          <MinimizeIcon
            sx={{
              position: 'absolute',
              right: theme.spacing(3),
              fontSize: theme.spacing(3),
            }}
          />
        ) : (
          <OpenInFullIcon
            sx={{
              position: 'absolute',
              right: theme.spacing(3),
              fontSize: theme.spacing(3),
            }}
          />
        )}
      </Box>
      {chatOpen && (
        <MainContainer>
          <ChatContainer>
            <MessageList>
              {messages.length > 0 ? (
                messages.map((element) =>
                  element.sender === `system${caseId}` ? (
                    <MessageSeparator key={element.sentTime}>
                      {element.message.text}
                    </MessageSeparator>
                  ) : (
                    <Message
                      key={(element.sentTime + caseId).toString()}
                      model={{
                        message: element.message.text,
                        sentTime: element.sentTime.toString(),
                        sender: element.sender,
                        direction:
                          element.sender ===
                          `office${report.officeFk}`.toString()
                            ? 'outgoing'
                            : 'incoming',
                      }}
                    >
                      <Message.Footer>
                        {formatDate(element.sentTime)}
                      </Message.Footer>
                    </Message>
                  )
                )
              ) : (
                <MessageSeparator>No messages yet</MessageSeparator>
              )}
            </MessageList>
            {user.uid === report.hrRepFk && report.caseStatus !== 'CLOSED' ? (
              <MessageInput attachButton={false} onSend={onSend} />
            ) : (
              <MessageInput
                disabled
                attachButton={false}
                placeholder="You are not authorized to send messages"
                onSend={onSend}
              />
            )}
          </ChatContainer>
        </MainContainer>
      )}
    </Box>
  );
}

export default ChatBox;
