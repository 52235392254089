import { React, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography, Link as MUILink } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import BallotIcon from '@mui/icons-material/Ballot';
// import SummarizeIcon from '@mui/icons-material/Summarize';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AttachmentIcon from '@mui/icons-material/Attachment';
import PersonIcon from '@mui/icons-material/Person';
import {
  fetchNotifications,
  fetchReadTimeTokens,
  fetchUnreadMessages,
} from 'pages/notifications/redux/notifications.thunk';
// import { setChatOpen } from 'pages/report-summary/redux/report-summary.slice';
import pubnub from 'common/config/pubnub/pubnub.config';

function HeaderNotificationsContent() {
  const icons = {
    caseClosed: <BallotIcon sx={{ marginRight: 2, color: 'icons.tertiary' }} />,
    caseSubmitted: (
      <BallotIcon sx={{ marginRight: 2, color: 'icons.tertiary' }} />
    ),
    caseCloseRequested: (
      <BallotIcon sx={{ marginRight: 2, color: 'icons.tertiary' }} />
    ),
    message: (
      <ChatBubbleIcon sx={{ marginRight: 2, color: 'icons.tertiary' }} />
    ),
    attAdded: (
      <AttachmentIcon sx={{ marginRight: 2, color: 'icons.tertiary' }} />
    ),
    caseAssigned: (
      <PersonIcon sx={{ marginRight: 2, color: 'icons.tertiary' }} />
    ),
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  // const [filter, setFilter] = useState('ALL');
  // const [readTokens, setReadTokens] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const readTokens = useSelector((state) => state.notifications.readTimeTokens);
  const unreadMessages = useSelector(
    (state) => state.notifications.unreadMessages
  );
  const first6 = notifications.slice(0, 6);
  const channelName = `notifications${user.uid}`;

  useEffect(() => {
    // whenever the header rerenders, check for notifications
    dispatch(fetchUnreadMessages());
    dispatch(fetchNotifications(channelName));
    // get list of all read messages
    dispatch(fetchReadTimeTokens(channelName));
  }, []);

  const markRead = (msgTimeToken, msg) => {
    if (!readTokens.includes(msgTimeToken)) {
      pubnub.addMessageAction(
        {
          channel: channelName,
          messageTimetoken: msgTimeToken,
          action: {
            type: 'updated',
            value: 'read',
          },
        },
        (status, response) => {
          const url =
            msg.type === 'caseSubmitted'
              ? '/reports'
              : `/report-summary/${msg.caseId}`;
          navigate(url);
          console.log(status, response);
        }
      );
    } else {
      // dispatch(setChatOpen(true));
      const url =
        msg.type === 'caseSubmitted'
          ? '/reports'
          : `/report-summary/${msg.caseId}`;
      navigate(url);
    }
  };

  return (
    <Box sx={{ width: 400, padding: 3 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 3,
          // borderBottom: '.5px solid',
        }}
      >
        <Typography
          variant="page_subtitle"
          sx={{
            marginRight: 'auto',
          }}
        >
          Notifications
        </Typography>
        <Link
          to="/notifications"
          style={{ color: theme.palette.text.allowed, fontWeight: 600 }}
        >
          See all
        </Link>
      </Box>
      {/* <Box sx={{ marginBottom: 3 }}>
        <Chip
          tabIndex={0}
          label="ALL"
          color={filter === 'ALL' ? 'primary' : 'default'}
          sx={{
            fontWeight: 600,
            marginRight: 3,
          }}
          onClick={() => setFilter('ALL')}
        />
        <Chip
          tabIndex={0}
          label="UNREAD"
          color={filter === 'UNREAD' ? 'primary' : 'default'}
          sx={{
            fontWeight: 600,
          }}
          onClick={() => setFilter('UNREAD')}
        />
      </Box> */}

      {unreadMessages.length !== 0 && (
        <Typography sx={{ fontWeight: 600, marginBottom: 3 }}>
          Messages
        </Typography>
      )}
      {unreadMessages.map((item) => (
        <Box
          key={item.caseId}
          sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
        >
          {icons.message}
          <MUILink
            sx={{ fontWeight: 600 }}
            underline="none"
            href={`/report-summary/${item.caseId}`}
          >
            {`You have ${item.unreads} new messages for case${item.caseId}.`}
          </MUILink>
        </Box>
      ))}

      {unreadMessages.length !== 0 && (
        <Typography sx={{ fontWeight: 600, marginBottom: 3 }}>
          Notifications
        </Typography>
      )}
      {first6.length === 0 && (
        <Typography sx={{ fontWeight: 100 }}>
          There are no new notifications
        </Typography>
      )}

      {first6.map((item) => (
        <Box
          key={item.timetoken}
          sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
        >
          {icons[item.message.type]}
          <MUILink
            sx={{
              fontWeight: readTokens.includes(item.timetoken) ? 400 : 600,
            }}
            underline="none"
            href="#"
            onClick={() => markRead(item.timetoken, item.message)}
          >
            {item.message.text}
          </MUILink>
        </Box>
      ))}

      {/* <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
        <BallotIcon sx={{ marginRight: 2 }} />
        <Typography sx={{ fontWeight: 600 }}>
          You have 4 new surveys sent in the last 2 weeks
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
        <BallotIcon sx={{ marginRight: 2 }} />
        <Typography sx={{ fontWeight: 600 }}>
          You have 4 new surveys sent in the last 2 weeks
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
        <BallotIcon sx={{ marginRight: 2 }} />
        <Typography sx={{ fontWeight: 600 }}>
          You have 4 new surveys sent in the last 2 weeks
        </Typography>
      </Box> */}
    </Box>
  );
}

export default HeaderNotificationsContent;
