import { React, useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, List } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { getDatabase, ref, onValue } from 'firebase/database';
import LinearProgress from '@mui/material/LinearProgress';
import LabelValuePairInfo from './label-value-pair-info.components';
import ChecklistItem from './checklist-item.components';
// import { getChecklist } from '../redux/report-summary.thunk';

function Checklist() {
  // const { caseId } = useParams();
  // const dispatch = useDispatch();
  const checklistDataRef = useSelector(
    (state) => state.reportSummary.checklistData
  );
  const report = useSelector((state) => state.reportSummary.report);
  const loading = useSelector((state) => state.reportSummary.loading);
  const [checklistData, setChecklistData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState('Assessment');

  const progressInfoText = { fontWeight: 600, fontSize: 12 };
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#E1E6EC',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FFBA79',
    },
  }));

  const getStageAndProgress = (checklist) => {
    if (report.caseStatus === 'CLOSED') {
      setStage('Closed');
      setProgress(100);
      return;
    }
    if (checklist) {
      if (checklist.follow_up.checked) {
        setStage('Follow up');
        setProgress(100);
      } else if (checklist.resolution.checked) {
        // Check if any of the follow_up's sub items are checked
        const followUpSubItems = checklist.follow_up.subItems;
        if (
          followUpSubItems &&
          Object.values(followUpSubItems).some((subItem) => subItem.checked)
        ) {
          setStage('Follow up');
        } else {
          setStage('Resolution');
        }
        setProgress(75);
      } else if (checklist.investigation.checked) {
        // Check if any of the resolution's sub items are checked
        const resolutionSubItems = checklist.resolution.subItems;
        if (
          resolutionSubItems &&
          Object.values(resolutionSubItems).some((subItem) => subItem.checked)
        ) {
          setStage('Resolution');
        } else {
          setStage('Investigation');
        }
        setProgress(50);
      } else if (checklist.assessment.checked) {
        // Check if any of the investigation's sub items are checked
        const investigationSubItems = checklist.investigation.subItems;
        if (
          investigationSubItems &&
          Object.values(investigationSubItems).some(
            (subItem) => subItem.checked
          )
        ) {
          setStage('Investigation');
        } else {
          setStage('Assessment');
        }
        setProgress(25);
      } else {
        setStage('Assessment');
        setProgress(0);
      }
    }
  };

  useEffect(() => {
    setChecklistData(checklistDataRef);

    getStageAndProgress(checklistDataRef);
  }, [checklistDataRef]);
  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: 'background.paper',
        borderRadius: '10px',
      }}
    >
      <LabelValuePairInfo
        label="RESOLUTION STEPS"
        value="Expand main steps to add and complete subtasks. If you uncheck a task, all subtasks will also be unchecked."
      />
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ ...progressInfoText }}>
          {`STAGE: ${stage}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '20fr 1fr',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <BorderLinearProgress variant="determinate" value={progress} />
        <Typography variant="p" sx={progressInfoText}>
          {`${progress}%`}
        </Typography>
      </Box>
      {checklistData && !loading && (
        <List>
          <ChecklistItem
            key="assessment"
            value={checklistData.assessment}
            label="assessment"
          />
          <ChecklistItem
            key="investigation"
            value={checklistData.investigation}
            label="investigation"
          />
          <ChecklistItem
            key="resolution"
            value={checklistData.resolution}
            label="resolution"
          />
          <ChecklistItem
            key="follow_up"
            value={checklistData.follow_up}
            label="follow_up"
          />
        </List>
      )}
    </Box>
  );
}

export default Checklist;
